$color-dark-blue: #245499;
$color-blue: #81d4fa;
$color-red: #cb0028;
$color-purple: #a902b6;
$color-green: #56a21e;
$color-yellow: #ff9300;
$color-orange: #ed870a;
$color-dark: #171c1d;
$color-gold: #a7905f;

$font-main: 'Roboto', sans-serif;
$font-secondary: 'PT Serif', serif;

// Flexbox Grid //
$flexboxgrid-grid-columns: 12;
$flexboxgrid-gutter-width: 10px;

$screen-sm-min: 544px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 2000px;
$screen-work-min: 1600px;

.wrapper {
	width: 100%;
	
	@media (min-width: $screen-xxl-min) {
		max-width: 2000px !important;
	}
}


.col-sm-offset-reset {
	@media (min-width: $screen-sm-min) {
		margin-left: 0;
	}
}

.col-md-offset-reset {
	@media (min-width: $screen-md-min) {
		margin-left: 0;
	}
}

.col-lg-offset-reset {
	@media (min-width: $screen-lg-min) {
		margin-left: 0;
	}
}

.col-xl-offset-reset {
	@media (min-width: $screen-xl-min) {
		margin-left: 0;
	}
}