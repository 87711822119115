.advantage {
	margin: 10px 0;
	font-family: $font-main;
	font-display: swap;
	font-size: 1rem;

	@media (min-width: $screen-md-min) {
		margin: 15px 0;
	}

	@media (min-width: $screen-work-min) {
		margin: 20px 0;
	}

	&--narrow {
		margin: 0;
		
		@media (min-width: $screen-md-min) {
			margin: 0 20px;
		}

		.advantage__img {
			width: 80%;

			@media (min-width: $screen-md-min) {
				width: 100%;
			}
		}
	}

	&__img {
		display: block;
		width: 100%;
		margin: 0 auto;

		&--small {
		    width: 40%;
		}
	}

	&__text {
		color: #fff;
		text-align: center;
	}
}