.about {
	height: auto;
	// pointer-events: none;
	transition: 0.3s ease;

	@media (min-width: $screen-md-min) {
		height: 45vh;
	}

	@media (min-width: $screen-lg-min) {
		height: 35vh;
		pointer-events: auto;
	}

	@media (min-width: $screen-work-min) {
		height: 15vh;
	}

	&--invisible {
		opacity: 0;
	}

	&--visible {
		opacity: 1;
	}

	&__content {
		&--hidden-xs {
			display: none;

			@media (min-width: $screen-lg-min) {
				display: block;
			}
		}
	}
}