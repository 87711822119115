.loader {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: transparentize(#fff, 0.3);

	&--visible {
		display: block;
	}

	&__inner {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	&__item {
		position: relative;
		display: inline-block;
		height: 15px;
		width: 15px;
		margin: 5px;
		border-radius: 50%;
		will-change: transform;
		animation: loader 0.8s ease-in-out alternate infinite;

		&--red {
			background-color: $color-red;
			animation-delay: 0;
		}

		&--yellow {
			background-color: $color-yellow;
			animation-delay: 0.1s;
		}

		&--green {
			background-color: $color-green;
			animation-delay: 0.2s;
		}

		&--blue {
			background-color: $color-blue;
			animation-delay: 0.3s;
		}

		&--dark-blue {
			background-color: $color-dark-blue;
			animation-delay: 0.4s;
		}

		&--purple {
			background-color: $color-purple;
			animation-delay: 0.5s;
		}
	}
}

@keyframes loader {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(200%);
	}

	100% {
		transform: translateY(0);
	}
}