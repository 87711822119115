// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
	font-size: 12px;

	@media (min-width: $screen-lg-min) {
		font-size: 14px;
	}
}

body {
	font-size: 1rem;
	overflow: hidden;
}

.amount {
	&__box {
		display: inline-block;
		padding: 0 5px;
		border-right: 1px solid #000;

		&:last-child {
			border-right: none;
		}
	}
}

.phone {
	text-decoration: none;
	color: inherit;
}

.button-icon {
	margin-right: 20px;
	width: 30px;

	@media (min-width: $screen-md-min) {
		width: 50px;
	}
}

.info {
	&__button {
		margin: 30px 0;
	}

	&__image {
		width: 100%;
	}
}

.visually-hidden {
	&--md {
		@media (max-width: $screen-work-min - 1px) {
			position: absolute;
			clip: rect(0 0 0 0);
			width: 1px;
			height: 1px;
		}
	}
}