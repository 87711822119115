.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1rem;
	font-family: $font-main;
	font-display: swap;
	background-color: #fff;

	&--cart {
		max-width: 960px;
		margin: 0 auto;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		// padding: 4em 25px 0 4em;
	}

	&__sum {
		font-size: 1.143em;
		color: lighten(#000, 65%);
	}

	&__sum-value {
		color: #000;
	}

	&__block {
		padding: 1.5em;

		@media (min-width: $screen-md-min) {
			padding: 4em;
		}

		&--bordered {
			border-left: 2px solid #d8d8d8;
		}

		&--full-width {
			padding-right: 0;
			padding-left: 0;
			padding-top: 0;
		}
	}

	&__input {
		width: 100%;
		margin: 10px 0;
		padding: 12px 15px;
		font-size: 1em;
		border: 1px solid #979797;
		border-radius: 0;
		appearance: none;
		resize: none;
	}

	&__submit {
		margin: 10px 0 0;

		@media (min-width: $screen-md-min) {
			margin: 20px 0 0;
		}
	}

	&__note {
		margin: 10px 0 0;

		@media (min-width: $screen-md-min) {
			margin: 30px 0 0;
		}
	}

	&__cart {
		max-height: 370px;
	}

	&__left {
		display: none;
		
		@media (min-width: $screen-lg-min) {
			display: block;
		}
	}
}