@mixin color-range() {
	&--dark-blue {
		color: $color-dark-blue;
	}

	&--blue {
		color: $color-blue;
	}

	&--red {
		color: $color-red;
	}

	&--green {
		color: $color-green;
	}

	&--yellow {
		color: $color-yellow;
	}

	&--orange {
		color: $color-orange;
	}

	&--purple {
		color: $color-purple;
	}

	&--gold {
		color: $color-gold;
	}
}

@mixin color-range-buttons() {
	&--dark-blue {
		color: $color-dark-blue;
		border-color: $color-dark-blue;

		&:hover,
		&:focus {
			background-color: $color-dark-blue;
		}
	}

	&--blue {
		color: $color-blue;
		border-color: $color-blue;

		&:hover,
		&:focus {
			background-color: $color-blue;
		}
	}

	&--red {
		color: $color-red;
		border-color: $color-red;

		&:hover,
		&:focus {
			background-color: $color-red;
		}
	}

	&--green {
		color: $color-green;
		border-color: $color-green;

		&:hover,
		&:focus {
			background-color: $color-green;
		}
	}

	&--yellow {
		color: $color-yellow;
		border-color: $color-yellow;

		&:hover,
		&:focus {
			background-color: $color-yellow;
		}
	}

	&--orange {
		color: $color-orange;
		border-color: $color-orange;

		&:hover,
		&:focus {
			background-color: $color-orange;
		}
	}

	&--purple {
		color: $color-purple;
		border-color: $color-purple;

		&:hover,
		&:focus {
			background-color: $color-purple;
		}
	}

	&--gold {
		color: $color-gold;
		border-color: $color-gold;

		&:hover,
		&:focus {
			background-color: $color-gold;
		}
	}
}

@mixin color-range-pseudo() {
	&-green {
		&:after {
			color: $color-green;
		}
	}

	&-yellow {
		&:after {
			color: $color-yellow;
		}
	}

	&-blue {
		&:after {
			color: $color-blue;
		}
	}

	&-purple {
		&:after {
			color: $color-purple;
		}
	}

	&-gray {
		&:after {
			color: #a7905f;
		}
	}

	&-white {
		&:after {
			color: #fff;
		}
	}

	&-red {
		&:after {
			color: $color-red;
		}
	}
}