.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: flex-end;
	background-color: transparentize(#fff, 0.3);
	z-index: 10;

	@media (min-width: $screen-lg-min) {
		right: -15px;
		left: auto;
		top: 50%;
		transform: translateY(-50%) translateX(35px);
		display: block;
		width: auto;
		height: auto;
		background-color: transparent;
	}

	&--visible {
		display: flex;
		animation: ShowModal 0.2s ease;
	}

	&--white {
		.navbar__item #Group-Copy-2 > g {
			@media (min-width: $screen-lg-min) {
				fill: #fff;
			}
		}
	}

	&__close {
		position: absolute;
		right: 20px;
		top: 20px;
	}
	
	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		transform: translateX(50px);

		@media (min-width: $screen-lg-min) {
			transform: none;
		}
	}

	&__link {
		display: block;
		height: 55px;
	    text-align: right;

		@media (min-width: $screen-xxl-min) {
			height: 65px;
		}

		svg {
			display: inline-block;
			height: 100%;

			.path-clip {
				@media (min-width: $screen-lg-min) {
				    transform: translate(100%, 0px);
				    transition: 0.4s ease;
				}
			}

			text {
				@media (min-width: $screen-lg-min) {
					opacity: 0;
					transition: 0.4s ease 0.2s;
				}
			}
		}
	}

	&__item {
		display: block;
		padding: 5px 20px;
		margin-left: 0;
		transition: 0.4s ease;

		@media (min-width: 378px) {
			padding: 10px 20px;
		}

		&--invisible-lg {
			@media (min-width: $screen-lg-min) {
				display: none;
			}
		}

		&--visible-lg {
			display: none;

			@media (min-width: $screen-lg-min) {
				display: block;
			}
		}

		#Group-Copy-2 > g {
			@media (min-width: $screen-lg-min) {
				fill: #9e9e9e;
				transition: 0.2s ease;
			}
		}

		#Group > path {
			transition: 0.05s ease;
		}

		&.active {
			#Group-Copy-2 > g {
				fill: inherit;
			}
		}

		&.active-sibling {
			transform: translateX(-10px);

			@media (min-width: $screen-xxl-min) {
				transform: translateX(-25px);
			}

			&:nth-child(1) {
				#Group-Copy-2 > g {
					fill: #cf697d;
				}
			}

			&:nth-child(2) {
				#Group-Copy-2 > g {
					fill: #e9b369;
				}
			}

			&:nth-child(3) {
				#Group-Copy-2 > g {
					fill: #95bb78;
				}
			}

			&:nth-child(4) {
				#Group-Copy-2 > g {
					fill: #81d5fa;
				}
			}

			&:nth-child(5) {
				#Group-Copy-2 > g {
					fill: #8891c8;
				}
			}

			&:nth-child(7) {
				#Group-Copy-2 > g {
					fill: #be6ac5;
				}
			}
		}

		&.hovered,
		&:hover,
		&:focus {
			transform: translateX(-20px);

			@media (min-width: $screen-xxl-min) {
				transform: translateX(-35px);
			}

			.path-clip {
				transform: translateY(0);
			}

			text {
				opacity: 1;
			}

			#Group-Copy-2 > g {
				fill: inherit;
			}
		}
	}
}