.controls {
	display: none;

	@media (min-width: $screen-md-min) {
		display: block;
		padding: 30px 0;
		font-size: 1rem;
	}

	@media (min-width: $screen-lg-min) {
		padding: 0;
	}

	@media (min-width: $screen-xl-min) {
		margin-bottom: 20px;
	}

	&__arrow {
		position: relative;
		display: block;
		width: 100%;
		padding: 0;
		background-color: transparent;
		border: none;
		border-radius: 0;
		cursor: pointer;
		transition: 0.2s ease;
		
		&:hover,
		&:focus {
			outline: none;

			.controls__img {
				opacity: 1;
			}
		}

		&--next {
			&.slick-disabled {
				transform: translateX(70%);
			}

			.controls__text {
				transform: translate(10px, -50%);
			}
		}

		&--prev {
			&.slick-disabled {
				transform: translateX(-70%);
			}

			.controls__text {
				transform: translate(-10px, -50%);
			}
		}
	}

	&__text {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin: 0 auto;
		font-family: $font-secondary;
		font-size: 1em;
		font-weight: 700;
	}

	&__img {
		width: 100%;
		opacity: 0.6;
		transition: 0.2s ease;
	}
}