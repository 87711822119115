.logo {
    display: block;
    width: 100%;
    margin-left: 15px;

    @media (min-width: $screen-md-min) {
        margin-left: 30px;
    }

    &--fit {
        margin: 0;
    }

    &--secondary {
		width: 100%;
		height: auto;
        margin: 0;

        @media (min-width: $screen-md-min) {
            height: 3rem;
            width: auto;
        }

        @media (min-width: $screen-xxl-min) {
            height: 4rem;
        }
    }
}