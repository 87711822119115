.subtitle {
	margin: 0 0 15px 0;
	font-size: 1.5rem;
	font-family: $font-secondary;
	font-weight: 400;
	text-align: center;

	@media (min-width: $screen-md-min) {
		margin: 15px 0;
		font-size: 1.857rem;
	}

	&--decorated {
		&:after {
			content: '…';
			display: block;
			text-align: center;
			font-size: 1.25em;
	    line-height: 1em;
			color: inherit;
		}
	}

	&--fit {
		margin: 0;
	}

	&--left {
		text-align: left;
	}

	&--white {
		color: #fff;
	}

	&--decor {
		@include color-range-pseudo();
	}
}