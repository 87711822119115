.counter {
	display: inline-flex;
	font-size: 1rem;
	font-family: $font-main;
	font-display: swap;
	color: #a2a2a2;
	border: 2px solid #a2a2a2;
	transition: 0.2s ease;

	&--purple {
		.counter__control--plus {
			color: $color-purple;
		}

		.counter__value {
			color: $color-purple;
		}
	}

	&--small {
		.counter__control,
		.counter__value {
			font-size: 1.125em;
		}
	}

	&__control,
	&__value {
		display: flex;
		align-items: center;
		padding: 0.4em 0.6em;
		font-size: 1.429em;
		transition: 0.2s ease;

		@media (min-width: $screen-md-min)  {
			font-size: 1.125em;
		}

		@media (min-width: $screen-xl-min)  {
			font-size: 1.429em;
		}
	}

	&__control {
		color: #a2a2a2;
		background-color: transparent;
		border: none;
		border-radius: 0;
		appearance: none;
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&--plus {
			color: $color-dark-blue;
		}
	}

	&__value {
		margin: 0;
		color: $color-dark-blue;
		transition: 0.2s ease;
	}
}