.button {
	flex-shrink: 0;
    flex-grow: 0;
    display: inline-block;
	padding: 0.65em 1.5em;
	font-family: $font-secondary;
	font-display: swap;
	font-size: 1rem;
	letter-spacing: 0.07em;
	color: #000;
	background-color: transparent;
	border: 2px solid currentColor;
	cursor: pointer;
	transition: 0.2s ease;
	text-decoration: none;

	@media (min-width: $screen-lg-min) {
		font-size: 1.286rem;
	}

	@media (min-width: $screen-work-min) {
		font-size: 1.429rem;
	}

	&--hidden {
		display: none;
		opacity: 0;
	}

	&--visible-lg {
		display: none;

		@media (min-width: $screen-lg-min) {
			display: inline-block;
		}
	}

	&--blank {
		padding: 0;
		display: block;
		border: none;
	}

	&--nav {
		height: 55px;
		width: 55px;
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
	}

	&--icon-red {
		background-image: url('/images/icons/nav-main-round.svg');
	}

	&--icon-dark-blue {
		background-image: url('/images/icons/nav-price-round.svg');
	}

	&--icon-blue {
		background-image: url('/images/icons/nav-quality-round.svg');
	}

	&--icon-green {
		background-image: url('/images/icons/nav-about-round.svg');
	}

	&--icon-yellow {
		background-image: url('/images/icons/nav-nano-round.svg');
	}

	&--icon-purple {
		background-image: url('/images/icons/nav-form-round.svg');
	}

	&--faded-in {
		animation: FadeIn 0.5s ease;
	}

	&--small {
		font-size: 0.7rem;
		border-width: 1px;

		@media (min-width: $screen-md-min) {
			font-size: 0.9286rem;
			border-width: 2px;
		}

		@media (min-width: $screen-lg-min) {
			font-size: 1rem;
		}
	}

	&--md {
	    font-size: 1.1rem
	}

	&--close {
		height: 15px;
		width: 15px;
		padding: 0;
		background-color: transparent;
		background-image: url('/images/icons/close-icon.svg');
		background-size: 100%;
		background-repeat: no-repeat;
		border: none;
		border-radius: 0;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}

		&:focus {
			opacity: 0.7;
			outline: none;
		}
	}

	&--underline {
		height: 14px;
		width: 100%;
		padding: 0;
		border: none;
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: center;

		@media (min-width: $screen-lg-min) {
			height: 16px;
		}
	}

	&--proper-age {
		background-image: url('/images/icons/proper-age.png');
	}

	&--improper-age {
		background-image: url('/images/icons/improper-age.png');
	}

	&:focus,
	&:hover {
		color: #fff;
		outline: none;
	}

	&:active {
		opacity: 0.7;
	}

	@include color-range-buttons();
}

@keyframes FadeIn{
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}