.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	opacity: 1;
	transition: 0.2s ease;
	z-index: 1000;

	&--invisible {
		opacity: 0;
	}

	&--hidden {
		display: none;
	}

	&__content {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__logo {
		width: 200px;
		margin: 30px;
	}

	&__progress {
		margin: 50px 0;
	}
}