.arrow {
	display: none;

	@media (min-width: $screen-lg-min) {
		position: fixed;
		bottom: 15px;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		height: 26px;
		width: 100px;
		background-image: url('../images/icons/arrow.svg');
		background-size: 100%;
		background-repeat: no-repeat;
		cursor: pointer;
		transition: 0.5s ease;
		z-index: 20;
	}

	&--visible {
		opacity: 1;
	}

	&--invisible {
		opacity: 0;
	}

	&--inverse {
		background-image: url('../images/icons/arrow-inverse.svg');
	}
}