.title {
	&--404 {
		display: flex;
		justify-content: center;
		margin: 30px 0;
		font-family: $font-secondary;
		font-size: 14rem;
		color: #9e9e9e;

		@media (min-width: $screen-lg-min) {
			justify-content: space-between;
		}
	}
}