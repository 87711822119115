.card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
  width: 100%;
	font-size: 1rem;
	font-family: $font-secondary;
	font-display: swap;
    text-align: center;
    transition: 0.2s ease;

	&--active {
		.card__bottle {
			transform: translateY(-30px);
			animation: WingardiumLeviosa 2s ease infinite;
			animation-delay: 1s;
		}

		.card__image-wrap--shadowed:after {
			animation: Evanesco 2s ease infinite;
		}
	}

	&--unactive {
		.counter {
			opacity: 0.5;
		}
		
		.counter__control--plus {
			color: #a2a2a2;
		}

		.counter__value {
			color: #a2a2a2;
		}
	}

	&--thumb {
		flex-direction: row;
		padding: 0 0 20px 0; 
		margin-top: 20px;
		font-family: $font-main;
		text-align: left;
		border-bottom: 1px solid #979797;

		&:last-child {
			border-bottom: none;
		}

		.card__price {
			margin-bottom: 0;
			font-size: 1em;
			text-align: right;

			&--small {
				margin-bottom: 2px;
				font-size: 0.8em;
			}
		}

		.card__title {
			margin-top: 0;
			padding-right: 30px;
			font-size: 1em;
			font-weight: 400;
			text-align: left;
		}
	}

	&__close {
		position: absolute;
		right: 0;
		top: 0;
	}

	&__label {
		position: absolute;
		left: 10%;
		top: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1em;

		@media (min-width: $screen-lg-min) {
			font-size: 1.429em;
		}

		@media (min-width: $screen-xxl-min) {
			left: 4vw;
		}

		&--empty {
			.card__drop {
				background-image: url('/images/icons/yas-empty.svg');
			}
		}
	}

	&__drop {
		margin-top: 5px;
		height: 18px;
		width: 12px;
		background-image: url('/images/icons/yas.svg');
		background-size: 100%;
		background-repeat: no-repeat;
	}

	&__figure {
		margin: 0;
		width: 100%;
	}

	&__image-wrap {
		position: relative;
		display: flex;
		align-items: flex-end;
		height: 100%;

		&--shifted {
			transform: translateX(-35%);
		}

		&--shadowed {
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -1%;
				display: block;
				height: 6%;
				width: 100%;
				background-image: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 29%,rgba(0,0,0,0.4) 45%,rgba(0,0,0,0) 72%,rgba(0,0,0,0) 100%);
			}
		}

	}

	&__image-composition {
		display: flex;
		justify-content: center;
		align-items: stretch;
		height: 35vh;
		margin-bottom: 20px;
		padding-top: 30px;

		@media (min-width: $screen-md-min) {
			height: 30vw;
		}

		@media (min-width: $screen-lg-min) {
			height: 15vw;
		}

		@media (min-width: $screen-work-min) {
			height: 20vw;
		}
	}

	&__box {
		width: 100%;
	}

	&__bottle {
		height: 95%;
		z-index: 1;
		transition: 1s ease;
		will-change: transform;

		&--covered {
			height: 98%;
		}
	}

	&__cover {
	    height: 48%
	}

	&__tubus {
		transform: translateY(-15px);
		height: 100%;
		z-index: 0;
	}

	&__title {
		display: block;
		margin: 0 auto 10px;

		@media (min-width: $screen-lg-min) {
			
		}

		@media (min-width: $screen-work-min) {
			
		}
	}

	&__price {
		margin-top: 0;
		font-size: 1em;

		@media (min-width: $screen-xl-min) {
			font-size: 1.429em;
		}

		&--decorated {
			&:before {
				content: '…';
				display: block;
				margin-bottom: 20px;
				font-size: 1.429em;
				line-height: 0;
				color: $color-dark-blue;

				@media (min-width: $screen-lg-min) {
					font-size: 1em;
				}
			}
		}

		&--small {
			font-size: 0.7143em;
			color: #a2a2a2;
		}
	}

	&__footer {
		margin-top: auto;

		&--row {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

@keyframes WingardiumLeviosa {
	0% {
		transform: translateY(-30px);
	}

	50% {
		transform: translateY(-15px);
	}

	100% {
		transform: translateY(-30px);
	}
}

@keyframes Evanesco {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.4;
		transform: scale(0.8);
	}

	100% {
		opacity: 1;
	}
}