.stage {
	position: relative;
	margin: 0 auto;
	padding: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
    justify-content: flex-end;

	&__back {
		height: 26.2%;
	}

	&__front {
		position: absolute;
		left: 50%;
	    bottom: 2.5%;
	    height: 20.8%;
	    transform: translateX(-50%);
	}

	&__bottle-group {
		position: absolute;
        bottom: 22%;
		height: 44%;
		// width: 100%;
	}

	&__bottle-content {
		position: absolute;
		left: 50%;
        transform: translateX(-50%);
	    height: 100%;
	}

	&__bottle-images {
		height: 100%;
		animation: WingardiumLeviosa 3s ease infinite;
	}

	&__bottle {
		display: block;
		margin: 0 auto;
		height: 100%;
		z-index: 0;
	}

	&__light {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		height: 51.5%;
		transition: 0.6s ease;
		animation: HideLight 8s ease infinite;
		z-index: 1;

		&--green {
			animation-delay: 0s;
		}

		&--red {
			animation-delay: 2s;
		}

		&--purple {
			animation-delay: 4s;
		}

		&--pink {
			animation-delay: 6s;
		}
	}

	&__shadow-block {
		position: absolute;
		left: 0;
		right: 0;
	    bottom: -8%;
		display: block;
		height: 8%;
		width: 100%;
		transform: scale(1);
		transition: 0.2s ease;
    	animation: ShadowChange 3s ease infinite;
	}

	&__bottle-shadow {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		animation: HideLight 8s ease infinite;

		&--green {
			animation-delay: 0s;
			background-image: radial-gradient(
				ellipse at center,
				transparentize(#3D7776, 0) 0%, 
				transparentize(#3D7776, 0) 29%, 
				transparentize(#3D7776, 0.4) 45%, 
				transparentize(#3D7776, 1) 72%,
				transparentize(#3D7776, 1) 100%
			);
		}

		&--red {
			animation-delay: 2s;
			background-image: radial-gradient(
				ellipse at center,
				transparentize(#972C2C, 0) 0%, 
				transparentize(#972C2C, 0) 29%, 
				transparentize(#972C2C, 0.4) 45%, 
				transparentize(#972C2C, 1) 72%,
				transparentize(#972C2C, 1) 100%
			);
		}

		&--purple {
			animation-delay: 4s;
			background-image: radial-gradient(
				ellipse at center,
				transparentize(#4747A8, 0) 0%, 
				transparentize(#4747A8, 0) 29%, 
				transparentize(#4747A8, 0.4) 45%, 
				transparentize(#4747A8, 1) 72%,
				transparentize(#4747A8, 1) 100%
			);
		}

		&--pink {
			animation-delay: 6s;
    		background-image: radial-gradient(
				ellipse at center,
				transparentize(#B972B5, 0) 0%, 
				transparentize(#B972B5, 0) 29%, 
				transparentize(#B972B5, 0.4) 45%, 
				transparentize(#B972B5, 1) 72%,
				transparentize(#B972B5, 1) 100%
			);
    	}
	}

	&__glass {
		position: relative;
		height: 71%;
	    transform: translateY(18%);
		z-index: 10;
		opacity: 0.7;
	}
}

@keyframes HideLight {
	0% {
		opacity: 1;
	}

	25% {
		opacity: 0;
	}

	75% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes WingardiumLeviosa {
	0% {
		transform: translateY(-30px);
	}

	50% {
		transform: translateY(-15px);
	}

	100% {
		transform: translateY(-30px);
	}
}

@keyframes ShadowChange {
	0% {
		opacity: 0.4;
		transform: scale(1);
	}

	50% {
		opacity: 1;
		transform: scale(1.1);
	}

	100% {
		opacity: 0.4;
		transform: scale(1);
	}
}