.cart {
	margin: 0;

	&__inner {
		flex: 1;
		max-height: 65vh;
		overflow-y: scroll;

		@media (min-width: $screen-lg-min) {
			max-height: 100%;
			overflow-y: auto;
		}
	}

	&__sum {
		flex: 1;
		font-size: 1rem;
		color: $color-purple;
		font-weight: 700;
		text-align: right;
	}

	.simplebar-content {
		padding-right: 15px;
	}

	.simplebar-scrollbar.visible {
		right: -2px;
		width: 6px;
	    border-radius: 0;
		opacity: 1;
	}

	.simplebar-track.vertical {
		right: 2px;
		width: 2px;
		background-color: #d8d8d8;
	}
}