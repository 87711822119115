.slider {
	flex: 1;
	overflow: hidden; // To prevent infinite width with using flexbox wrapper //

	&.slick-dotted {
		margin-bottom: 0;
	}
	
	&--advantages {
	    @extend .col-xs-12;
	    @extend .col-md-8;

	    @media (min-width: $screen-work-min) {
			    flex-grow: 0;
				flex-shrink: 0;
				flex-basis: 100%;
				max-width: 100%;
	    }
	}

	&--promo {
		.slick-dots {
			bottom: 6vh;

			li button:before {
				font-size: 35px;
				opacity: 1;
			}

			li.slick-active button:before {
				color: $color-green;
				opacity: 1;
			}
		}

		.slick-arrow {
			cursor: pointer;
			height: 100%;
			width: 10%;
			z-index: 1;

			@media (min-width: $screen-sm-min) {
				width: 5%;
			}

			&:before {
				content: '';
				display: block;
				height: 40px;
				width: 40px;
				margin: 0 auto;
				background-image: url('/images/icons/slider-arrow.svg');
				background-size: auto 100%;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.slick-prev {
			left: 0;

			@media (min-width: $screen-sm-min) {
				left: 2%;
			}

			&:before {
				transform: rotateY(180deg)
			}
		}

		.slick-next {
			right: 0;

			@media (min-width: $screen-sm-min) {
				right: 2%;
			}
		}

		.slick-list,
		.slick-slide,
		.slick-track,
		.slick-slide > div,
		.wrapper,
		.container-fluid,
		.row {
			height: 100%;
		}
	}

	&--pricelist {
		position: relative;
		display: flex;
		align-items: center;

		.slick-list {
			@media (min-width: $screen-lg-min) {
				padding: 0 40px;
				mask-image: linear-gradient(to right, transparentize(#000, 1) 0%, #000 7%, #000 93%, transparentize(#000, 1) 100%);
			}
		}

	    .slick-track {
	    	display: flex;
	    }

	    .slick-slide {
	    	height: auto;

	    	& > div {
	    		height: 100%;
	    	}
	    }

	    .slick-dots {
			left: 0;
			right: 0;
			bottom: -35px;
			width: auto;

	    	li button:before {
	    		font-size: 38px;
	    		opacity: 1;
	    	}

	    	li.slick-active button:before {
	    		color: $color-orange;
	    	}
	    }

	    .slider__item {
	    	display: flex !important;
	    }

	    .card__title,
	    .card__footer {
	    	opacity: 0;
	    	transition: 0.2s ease;

	    	@media (min-width: $screen-md-min) {
	    		opacity: 1;
	    	}
	    }

	    .slick-current {
	    	.card__title,
	    	.card__footer {
	    		opacity: 1;
	    	}
	    }
	}

	&__background {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 600%;
		transition: 0.48s ease;
		transform: translateY(-120%);

		@media (min-width: $screen-md-min) {
			width: 200%;
		}
	}

	&__chart {
		// !important потому, что slick по дефолту ставит block //
		display: none !important;

		@media (min-width: $screen-work-min) {
			display: block !important;
			margin-bottom: 20px;
			width: 100%;
		}

		&--second {
			width: 125%;
		    margin-left: -25%;
		}
	}

	&__content {
		width: 100%;
		
		@media (min-width: $screen-lg-min) {
			transform: translateY(-10%);
		}
	}

	&__content-wrap {
		display: flex;
	    align-items: flex-start;

	    @media (min-width: $screen-lg-min) {
	    	align-items: center;
	    }
	}

	&__text {
		max-height: 34vh;
		overflow-y: scroll;

		p:first-child {
			margin-top: 0;
		}

		@media (min-width: $screen-lg-min) {
			max-height: none;
			overflow: auto;
		}
	}

	&__item {
		height: 100%;
		background-size: auto 50%;
		background-position: left bottom;
		background-repeat: no-repeat;

		@media (min-width: $screen-md-min) {
			background-size: auto 70%;
		}

		@media (min-width: $screen-lg-min) {
			background-size: auto 100%;
		}

		&--first {
			background-image: url('/images/slides/03-01.jpg');

			@media (min-width: $screen-md-min) {
				background-image: url('/images/slides/03-01@2x.jpg');
			}
		}

		&--second {
			background-image: url('/images/slides/03-02.jpg');

			@media (min-width: $screen-md-min) {
				background-image: url('/images/slides/03-02@2x.jpg');
			}
		}

		&--third {
			background-image: url('/images/slides/03-03.jpg');

			@media (min-width: $screen-md-min) {
				background-image: url('/images/slides/03-03@2x.jpg');
			}
		}

		&--fourth {
			background-image: url('/images/slides/03-04.jpg');

			@media (min-width: $screen-md-min) {
				background-image: url('/images/slides/03-04@2x.jpg');
			}
		}
	}
}