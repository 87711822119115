.explode {
	position: relative;
	height: 0;
	padding-top: 20%;
	transform: rotate(90deg) scale(1.4) translateX(60%);

	@media (min-width: $screen-md-min) {
		transform: rotate(90deg) scale(1) translateX(55%);
	}

	@media (min-width: $screen-lg-min) {
		transform: translateX(-6%);
	}

	&__inner {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		transition: 0.3s ease;

	    &--active {
			.explode__butilka {
				transform: translateX(69%);
			}

			.explode__korpus-1 {
				transform: translateX(180%);
			}

			.explode__korpus {
		        transform: translateX(252%);
			}

			.explode__layer-16 {
		        transform: translateX(245%);
			}

			.explode__batareika-01 {
				&--top {
				    transform: translateX(263%);
				}

				&--bottom {
				    transform: translateX(265%);
				}
			}

			.explode__batareika-02 {
				&--top {
				    transform: translateX(244%);
				}

				&--bottom {
					transform: translateX(250%);
				}
			}

			.explode__batareika-03--top {
				transform: translateX(270%);
			}

			.explode__dathik-01 {
			    transform: translateX(1170%);
			}

			.explode__dathik-02 {
			    transform: translateX(2130%);
			}

			.explode__svet {
				transform: translateX(222%);
			}

			.explode__karkac {
			    transform: translateX(67%);
			}

			.explode__voronka {
			    transform: translateX(-73%);
			}

			.explode__filtr {
				&-6 {
				    transform: translateX(-280%);
				}

				&-5 {
					transform: translateX(-222%);
				}

				&-4 {
					transform: translateX(-300%);
				}

				&-3 {
					transform: translateX(-300%);
				}

				&-2 {
					transform: translateX(-400%);
				}

				&-1 {
					transform: translateX(-400%);
				}
			}

			.explode__krihka-niz {
				transform: translateX(-277%);
			}

			.explode__krihka-verx {
				transform: translateX(-222%);
			}

			.explode__kolba-niz-copy-2 {
				    transform: translateX(-210%);
			}

			.explode__kolba-verx {
			    transform: translateX(-88%);
			}

			.explode__kolpahek-niz {
			    transform: translateX(-302%);
			}

			.explode__kolpahek-verx {
				transform: translateX(-348%);
			}

			.explode__tubus-niz {
				transform: translateX(-185%);
			}

			.explode__tubus-verx {
			    transform: translateX(-185%);
			}
	    }

	    &--fully-active {
	    	.explode__voronka {
			    transform: translateX(-20%);
			}

			.explode__filtr {
				&-5 {
					transform: translateX(-287%);
				}

				&-4 {
				    transform: translateX(-353%);
				}

				&-3 {
					transform: translateX(-416%);
				}

				&-2 {
					transform: translateX(-678%);
				}

				&-1 {
					transform: translateX(-732%);
				}
			}

			.explode__krihka-niz {
			    transform: translateX(-457%);
			}

			.explode__krihka-verx {
			    transform: translateX(-345%);
			}

			.explode__layer-16 {
		        transform: translateX(235%) translateY(-109%);
			}

			.explode__batareika-01 {
				&--top {
				    transform: translateX(263%) translateY(-288%);
				}

				&--bottom {
				    transform: translateX(265%) translateY(141%);
				}
			}

			.explode__batareika-02 {
				&--top {
				    transform: translateX(244%) translateY(-410%);
				}

				&--bottom {
					transform: translateX(250%) translateY(100%);
				}
			}

			.explode__batareika-03--top {
				transform: translateX(270%) translateY(-314%);
			}

			.explode__dathik-01 {
			    transform: translateX(1170%) translateY(33%);
			}

			.explode__dathik-02 {
			    transform: translateX(2130%) translateY(70%);
			}

			.explode__svet {
				transform: translateX(222%) translateY(155%);
			}
	    }
	}

	&__particle {
		position: absolute;
		transition: 0.6s ease;
		backface-visibility: hidden;
	}

	&__butilka {
		top: 19%;
		right: 17%;
		height: 60%;
		z-index: 0;
	}

	&__korpus-1 {
		height: 44%;
		top: 29%;
		right: 40%;
		z-index: 10;
	}

	&__korpus {
		height: 45%;
		top: 26%;
		right: 42%;
		z-index: 5;
	}

	&__layer-16 {
		height: 19%;
		top: 36%;
		right: 43%;
		z-index: 4;
	}

	&__batareika-01 {
		&--top {
			height: 8%;
			right: 42.5%;
			top: 32%;
			z-index: 6;
		}

		&--bottom {
			height: 8%;
			right: 43%;
			top: 59%;
			z-index: 4;
		}
	}

	&__batareika-02 {
		&--top {
			height: 8%;
			right: 42%;
			top: 31%;
			z-index: 5;
		}

		&--bottom {
			height: 8%;
			right: 42.5%;
			top: 59%;
			z-index: 3;
		}
	}

	&__batareika-03--top {
		height: 8%;
		right: 43%;
		top: 32%;
		z-index: 4;
	}

	&__dathik-01 {
		height: 6%;
		right: 43.5%;
		top: 65%;
		z-index: 10;
	}

	&__dathik-02 {
		height: 5%;
		right: 45.5%;
		top: 65%;
		z-index: 10;
	}

	&__svet {
		height: 11.5%;
		right: 39%;
		top: 63%;
		z-index: 20;
	}

	&__karkac {
		height: 50%;
		right: 39.5%;
		top: 24%;
		z-index: 50;
	}

	&__klho-vkl {
		height: 50%;
		right: 43%;
		top: 24%;
		z-index: 60;
	}

	&__voronka {
		height: 41%;
		right: 44.8%;
		top: 29%;
		z-index: 50;
	}

	&__filtr {
		&-6 {
			height: 39%;
		    right: 48%;
		    top: 30%;
			z-index: 45;
		}

		&-5 {
			height: 39%;
		    right: 48%;
		    top: 30%;
			z-index: 45;
		}

		&-4 {
			height: 39%;
		    right: 48%;
		    top: 30%;
			z-index: 45;
		}

		&-3 {
			height: 39%;
		    right: 48%;
		    top: 30%;
			z-index: 45;
		}

		&-2 {
			height: 39%;
		    right: 48%;
		    top: 30%;
			z-index: 45;
		}

		&-1 {
			height: 39%;
		    right: 48%;
		    top: 30%;
			z-index: 45;
		}
	}

	&__krihka-niz {
		height: 45%;
        right: 48.5%;
	    top: 27%;
		z-index: 45;
	}

	&__krihka-verx {
		height: 45%;
		right: 47%;
		top: 27%;
	    z-index: 50
	}

	&__kolba-niz-copy-2 {
		height: 47%;
		right: 42%;
		top: 26%;
		z-index: 50;
	}

	&__kolba-verx {
		height: 49%;
		right: 43.2%;
		top: 25%;
		z-index: 60;
	}

	&__kolpahek-niz {
		height: 21%;
		right: 53.5%;
		top: 39%;
		z-index: 40;
	}

	&__kolpahek-verx {
		height: 21%;
		right: 53.5%;
		top: 39%;
		z-index: 70;
	}

	&__tubus-niz {
		height: 49.5%;
		right: 39.3%;
		top: 24.5%;
		z-index: 40;
	}

	&__tubus-verx {
		height: 49.5%;
		right: 39.3%;
		top: 24.5%;
		z-index: 80;
	}
}