.instruction {
	&--hidden-lg {
		display: block;

		@media (min-width: $screen-lg-min) {
			display: none;
		}
	}

	&--visible-lg {
		display: none;
		
		@media (min-width: $screen-lg-min) {
			display: block;
		}
	}

	&__item {
		position: relative;
		height: 0;
		padding-top: 100%;
		margin: 7px 0;
	}

	&__content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('/images/icons/instruction.jpg');
		background-position: center;
		background-size: 203%;
		background-repeat: no-repeat;

		&--step-1 {
			background-position: 0 0;
		}

		&--step-2 {
			background-position: 98% -0px;
		}

		&--step-3 {
			background-position: 0 33.1%;
		}

		&--step-4 {
			background-position: 98% 33.1%;
		}

		&--step-5 {
			background-position: 0 66.35%;
		}

		&--step-6 {
			background-position: 98% 66.35%;
		}

		&--step-7 {
			background-position: 0 100%;
		}

		&--step-8 {
			background-position: 98% 100%;
		}
	}
}