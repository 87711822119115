.modal {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	font-size: 1rem;
	background-color: transparentize(#000, 0.3);
	z-index: 100;
	transition: 0.2s ease;

	&--visible {
		display: block;
		animation: ShowModal 0.2s ease;
	}

	&__wrapper {
		flex: 1;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	&__window {
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: 70vh;
	    max-height: 90vh;
		max-width: 760px;
		padding: 0.7em 1.5em;
		margin: 0 auto;
		background-color: #fff;

		@media (min-width: $screen-md-min) {
			min-height: 45vh;
			padding: 1.429em 2.857em;
		}

		@media (min-width: $screen-xxl-min) {
			min-height: 30vh;
		}
	}

	&__item {
		&--max {
			flex: 1;
		}

		&--sticked-to-bottom {
			margin-top: auto;
		}
	}

	&__title {
		margin-top: 2.857rem;
	}

	&__close {
		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		@media (min-width: $screen-md-min) {
			right: 2.857rem;
			top: 2.857rem;
		}
	}

	&__image {
		position: absolute;
		left: 0;
	    bottom: -20px;
		transform: translateX(-20%);
		height: 393px;
		z-index: 10;

		@media (min-width: $screen-md-min) {
			height: 530px;
			transform: translateX(-50%);
		}

		@media (min-width: $screen-work-min) {
			height: 640px;
			transform: translateX(-50%);
		}

		&--centered {
			@media (min-width: $screen-md-min) {
				bottom: 50%;
				transform: translate(-50%, 50%);
			}
		}

		&--small-sm {
			height: 40%;

			@media (min-width: $screen-md-min) {
				height: 530px;
			}

			@media (min-width: $screen-work-min) {
				height: 640px;
			}
		}
	}

	&__block {
		&--visible-lg {
			display: none;

			@media (min-width: $screen-lg-min) {
				display: block;
			}
		}

		&--hidden-lg {
			display: block;

			@media (min-width: $screen-lg-min) {
				display: none;
			}
		}
	}

	&__promo {
		margin: 20px 0 20px;
		padding: 21px 0;
		border-top: 1px solid $color-gold;
		border-bottom: 1px solid $color-gold;

		@media (min-width: $screen-md-min) {
		    margin: 20px 0 20px;
		    padding: 28px 0;
	    }

	    @media (min-width: $screen-lg-min) {
		    padding: 21px 0;
	    }

		@media (min-width: $screen-work-min) {
			margin: 60px 0 20px;
			padding: 35px 0;
		}

		&--moved-top {
			margin-top: 20px
		}

		&--moved-bottom {
			margin-bottom: 26px;

		    @media (min-width: $screen-md-min) {
			    margin-bottom: 38px;
		    }

		    @media (min-width: $screen-work-min) {
				margin-bottom: 54px;
		    }
		}

		&--fit-top {
			margin-top: 0;
		}

		&--small-space {
			margin: 8px 0;
		}
	}

	&__promo-title {
		font-size: 1.5rem;

		@media (min-width: $screen-md-min) {
			font-size: 2.714rem;
		}
	}

	&__form {
	    margin-bottom: 30px;
	}

	&__footer {
		display: flex;
	    justify-content: center;

		&--column {
			flex-direction: column;
		}
	}

	&__button {
		margin: 0 10px;

		&--permission {
			display: block;
		    margin: 10px auto;
		}

		&--shifted {
			margin: 30px 0;
		}

		&--space-around {
			margin: 30px 0;
		}

		&--bottom {
			@media (min-width: $screen-lg-min) {
				margin: 0 10px -10px;
			}

			@media (min-width: $screen-work-min) {
				margin: 10px 10px 0;
			}
		}
	}

	&__block-overflow {
		margin-right: -15px;
		max-height: 305px;

		.simplebar-track.vertical {
			right: 2px;
			width: 1px;
		    background-color: transparentize($color-gold, 0.6);
		}

		.simplebar-content {
			padding-right: 30px;
		}

		.simplebar-scrollbar.visible {
			right: -1px;
			width: 3px;
			background-color: $color-gold;
		    border-radius: 0;
			opacity: 1;
		}
	}
}