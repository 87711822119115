.section {
	font-family: $font-main;
	font-display: swap;
	font-size: 1rem;
	overflow: hidden;
	transition: 0.7s ease;

	@media (min-width: $screen-md-min) {
		display: flex;
		flex-direction: column;
	    justify-content: center;
	}

	.fp-scrollable {
		z-index: 1;
	}

	&--advantages {
		background-image: url('/images/backgrounds/advantages@1x.jpg');
		background-size: cover;
		background-repeat: no-repeat;

		@media (min-width: $screen-md-min) {
			background-image: url('/images/backgrounds/advantages@2x.jpg');
		}
	}

	&--form {
		background-image: url('/images/backgrounds/form-bg@1x.jpg');
		background-size: cover;
		background-repeat: no-repeat;

		@media (min-width: $screen-md-min) {
			background-image: url('/images/backgrounds/form-bg@2x.jpg');
		}
	}

	&--slider {
		display: flex;
		flex-direction: column;
	}

	&--pricelist {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&--scheme {
		background-image: linear-gradient(to bottom, transparentize(#000, 1) 70%, #000);		
	}

	&--main {
		position: relative;
		background-image: url('/images/backgrounds/main@1x.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		box-shadow: inset 0 0 90px 20px transparentize(#000, 0.35);
		justify-content: space-between;
		height: 100vh;

		@media (min-width: $screen-md-min) {
			background-image: url('/images/backgrounds/main@2x.jpg');
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 20vh;
			background-image: linear-gradient(
				to bottom, 
				transparentize($color-dark, 1) 0%, 
				transparentize($color-dark, 0.4) 58%, 
				transparentize($color-dark, 0.3) 66%, 
				transparentize($color-dark, 0) 98%
			);
			
		}
	}

	&--cart {
		@media (min-width: $screen-lg-min) {
			display: none;
		}
	}

	&--dark {
		background-color: $color-dark;
	}

	&__about-block {
		position: absolute;
		left: 30px;
		right: 30px;
		bottom: 15px;

		@media (min-width: $screen-lg-min) {
			position: static;
		}
	}

	&__block-fit {
		margin: 0 -2rem;

		@media (min-width: $screen-sm-min) {
			margin: 0;
		}
	}

	&__main-block {
		display: flex;
		flex-direction: column;
		height: 100vh;
		z-index: 1;
	}

	&__main-header {
		height: 20%;
	}

	&__main-inner {
		height: 80%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: stretch;

		@media (min-width: $screen-lg-min) {
			flex-direction: row;
		}
	}

	&__main-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
	    align-items: center;
		flex: 1;
		padding: 0 10px;

		&--main {
			order: 1;
		    flex: 2;

			@media (min-width: $screen-lg-min) {
				order: 0;
			    flex: 1;
			}
		}
	}

	&__box {
		display: flex;
		align-items: center;
	}

	&__stage {
		height: 50vh;
		padding: 15px 30px 0;
	    
	    @media (min-width: $screen-lg-min) {
	    	height: 100%;
	    	padding: 0 30px;
	    }
	}

	&__item {
		&--visible-lg {
			display: none;

			@media (min-width: $screen-lg-min) {
				display: block;
			}
		}

		&--invisible-lg {
			display: block;
			
			@media (min-width: $screen-lg-min) {
				display: none;
			}
		}

		&--visible-lg-flex {
			display: none;
			
			@media (min-width: $screen-lg-min) {
				display: flex;
			}
		}

		&--centered {
			position: absolute;
			right: -15px;
			top: 50%;
			transform: translateY(-50%);
		}

		&--moved {
			position: absolute;
			right: -15px;
		}

		&--squeezed-xxl {
			@media (min-width: $screen-xxl-min) {
			    padding: 0 10em;
			}
		}
	}
	
	&__header {
		position: relative;
		padding: 1.5em 0;
	    margin-bottom: auto;

		@media (min-width: $screen-xxl-min) {
			padding: 2.2em 0;
		}

		&--absolute {
			width: 100%;

			@media (min-width: $screen-md-min) {
				position: absolute;
				top: 0;
			}
		}
	}

	&__header-text,
	&__header-button {
		display: none;

		@media (min-width: $screen-lg-min) {
			display: block;
		}
	}

	&__header-button {
		margin-left: 20px;
		font-size: 1.1rem;

		@media (min-width: $screen-work-min) {
			font-size: 1.1rem;
		}
	}

	&__footer {
		padding: 1.5vw 0;

		@media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1px) {
			padding: 3vw 0;
		}
	}

	&__inner {
		&--center-lg {
			display: flex;
			flex: 1;
			align-items: flex-start;

			@media (min-width: $screen-lg-min) {
				align-items: center;
			}
		}

		&--top {
		    margin-bottom: auto;
		}

		&--absolute {
			display: flex;
		    align-items: center;
			width: 100%;

			@media (min-width: $screen-md-min) {
				position: absolute;
				align-items: flex-end;
				bottom: 0;
			}
		}

		&--stretch {
			flex: 1;
		}

		&--flex {
			display: flex;
			flex-direction: column;
		}

		&--end {
		    justify-content: flex-end;
		}
	}

	&__helper {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 100vh;
	}

	&__wrapper {
	    flex-grow: 1;
	}

	&__phone {
		@media (min-width: $screen-lg-min) {
			padding-right: 40px;
		}
	}
}