#fp-nav.right {
    right: 20px;
}

#fp-nav ul li {
	width: 40px;
	height: 40px;
	margin: 15px;

	&:hover {
		a span,
		a.active span {
			height: 100%;
			width: 100%;
			margin: 0;
		}
	}

	a span {
		position: relative;
		left: 0;
		top: 0;
		display: block;
		height: 100%;
		width: 100%;
		margin: 0;
	}

	a.active span {
		height: 100%;
		width: 100%;
		margin: 0;
	}

	&:nth-of-type(1) .active span {
		background-color: $color-dark-blue;
	}

	&:nth-of-type(2) .active span {
		background-color: $color-blue;
	}

	&:nth-of-type(3) .active span {
		background-color: $color-green;
	}

	&:nth-of-type(4) .active span {
		background-color: $color-yellow;
	}

	&:nth-of-type(5) .active span {
		background-color: $color-orange;
	}

	&:nth-of-type(6) {
		@media (min-width: $screen-lg-min) {
			display: none;
		}
	}

	&:nth-of-type(6) .active span {
		background-color: $color-purple;
	}

	&:nth-of-type(7) .active span {
		background-color: $color-purple;
	}
}