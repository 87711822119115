.text {
	margin: 15px 0;
	font-size: 1rem;
	line-height: 1.4em;
	text-align: left;

	&--serif {
		font-family: $font-secondary;
		font-display: swap;
	}

	&--big {
		font-size: 1.4rem;

		@media (min-width: $screen-lg-min) {
			font-size: 1.571rem;
		}

		@media (min-width: $screen-xl-min) {
			font-size: 2.143rem;
		}
	}

	&--mid {
		font-size: 1.286rem;

		@media (min-width: $screen-xl-min) {
			font-size: 1.714rem;
		}
	}

	&--huge {
		font-size: 4.286rem;
	}

	&--bold {
		font-weight: 700;
	}

	&--center {
		text-align: center;

		&-xs {
			text-align: center;
		}
	}

	&--right {
		text-align: right;

		&-lg {
			@media (min-width: $screen-lg-min) {
				text-align: right;
			}
		}
	}

	&--white {
		color: #fff;
	}

	&--half-visible {
		opacity: 0.5;
	}

	&--dark-blue {
		color: $color-dark-blue;
	}

	&--base-line {
	    line-height: 1;
	}

	&--fit {
		margin: 0;
	}

	@include color-range();

	&__part {
		font-size: 1.3rem;
		font-weight: 400;
		color: #000;
	}

	abbr {
		text-decoration: underline;
	}

	a {
		color: inherit;
		text-decoration: none;
	}
}